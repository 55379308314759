/*React imports*/
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { initUsers, initPatientToTherapist, therapistEmailWhitelist } from "./data/UserData"

/*routing imports*/
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
} from "react-router-dom";
import Home from "./Home";
import About from "./About";
import LogIn from "./LogIn";
import Signup from "./Signup"
import Prototype from "./Prototype"
import Meetteam from "./Meetteam"
import Messages from "./Messages"
import Managepatients from "./Managepatients"

/*CSS imports*/
import './css/navbar.css'
import './css/home.css'
import './css/site.css'
import './css/slideshow.css'

function NavBar() {
	
	//State hooks related to user management.
	const[user, setUser] = useState(null);
	const[users, setUsers] = useState(initUsers);
	const[subscribed, setSubscribed] = useState([]);
	const[patientToTherapist, setPatientToTherapist] = useState(initPatientToTherapist);
	
	// Functions to manage state
	const addNewUser = (user) => {
		//Generate an ID for the new user
		user.id = Math.floor(Math.random() * 1000) + "";
		setUsers([...users,user]);
	}
	
	const setCurrentUser = (user) => {
		setUser(user);
	}
	
	const addNewSubscribed = (newSubscribed) => {
		setSubscribed([...subscribed,newSubscribed]);
	}

	// Dynamic navigation
	// User signed in?
	const ConditionalRouting = () => {
		if(user != null){
			return (
				<>
					<Link to="/messages">Messages</Link>
					<span id="navFloatLeft">Welcome {user.firstName}</span>
					<Link onClick={() => setUser(null)} to="/" id="navFloatLeft">Log Out</Link>
					{user.isTherapist &&
						<Link to="/manage">Manage Patients</Link>
					}
				</>
			);
		}// User not signed in
		return (
			<>
				<Link id="navFloatLeft" to="/login">Login</Link>
				<Link id="navFloatLeft" to="/signup">Sign Up</Link>
			</>
		);
	}
	
	// Static Navigation
	return (	
	<section>
		<Router>
			<nav className='navbar'>
				<Link to="/">Home</Link>
				<Link to="/about">About us</Link>
				<Link to="/prototype">See Prototype</Link>
				<Link to="/team">Meet the Team</Link>
				<ConditionalRouting/>
			</nav>
			<Switch>
				<Route exact path="/">
					<Home/>
				</Route>
				<Route path="/about">
					<About setNewSubscribed={addNewSubscribed}/>
				</Route>
				<Route path="/login">
					<LogIn setLoggedInUser={setCurrentUser} users={users}/>
				</Route>
				<Route path="/signup">
					<Signup signUpNewUser={addNewUser} whitelist={therapistEmailWhitelist}/>
				</Route>
				<Route path="/prototype">
					<Prototype/>
				</Route>
				<Route path="/team">
					<Meetteam/>
				</Route>
				<Route path="/messages">
					<Messages currentUser={users[2]} users={users}/>
				</Route>
				<Route path="/manage">
					<Managepatients currentUser={user} users={users} patientToTherapist={patientToTherapist}/>
				</Route>
			</Switch>		
		</Router>
	</section>
	);
}

ReactDOM.render(<NavBar/>, document.getElementById("root"));