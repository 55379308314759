import React, { useState } from "react";
import { validateEmail, validatePassword } from "./data/validation.js";

const LogIn = ({ setLoggedInUser, users }) => {
	
	// Input hooks
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	
	// Form error/validation messaging
	const [loginError, setLoginError] = useState("");
	const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [confirmMessage, setConfirmMessage] = useState('');
	
	// Handle form submission and messaging 
	const submitLogin = () => {
		
		let validEmail = validateEmail(email);
		let validPassword = validatePassword(password);
		setEmailError(validEmail ? "": "- Invalid email!");
        setPasswordError(validPassword ? "": "- You must add a password!");

		if(validEmail && validPassword) {
			for (let i =0; i < users.length; i++) {
				if(users[i]["email"] === email && users[i]["password"] === password) {
					setLoginError("");
					setLoggedInUser(users[i]);
					setConfirmMessage('Login successful.');
					
					setEmail('');
					setPassword('');
					return;
				}
			}
			setLoginError("Incorrect Email or Password");
		}
		setConfirmMessage('');
	}
	
	const emailInput = () => (
		<label>
			Email <span className="error">{emailError}</span>
			<input
				type="text"
				name="userEmail"
				value={email}
				placeholder="you@example.com"
				onChange={e => setEmail(e.target.value)}/>
		</label>
	);
	
	const passwordInput = () => (
		<label>
			Password <span className='error'>{passwordError}</span>
			<input
				type="password"
				name="pass"
				value={password}
				placeholder="**************"
				onChange={e => setPassword(e.target.value)}/>
		</label>
	);
	
	return (
		<section className="content">
			<div className="transbox">
				<div className="text-display">
					<h1>Log In</h1>
				</div>
			</div>
			<div className="transbox">
				<div className='inputForm'>
					{emailInput()}
					{passwordInput()}
					<div className='formButton'
						onClick={() => submitLogin()}>
						{"Log In"}
					</div>
					<br/>
					<span className="error">{loginError}</span>
					<span style={{color: 'green'}}>{confirmMessage}</span>
				</div>
			</div>
		</section>
	);
};

export default LogIn;
