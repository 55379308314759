import React, { useState } from "react";
import { validateEmail } from "./data/validation.js";

const About = ({setNewSubscribed}) => {

	// Form error/validation messaging
	const [emailError, setEmailError] = useState('');
	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [confirmMessage, setConfirmMessage] = useState('');

    // Input hooks
	const [firstName, update_firstName] = useState('');
	const [lastName, update_lastName] = useState('');
	const [email, update_email] = useState('');
	

	const AboutText = () => (
		<div className="text-display">
				<span>A study shows that only thirty-five percent of physical therapy patients
				do their prescribed exercises by their therapist on their own at home.
				Additionally, most of these exercises are not performed correctly.</span>
				<br/><br/>
				<span>This project attempts to tackle that problem using the Microsoft HoloLens. The project has undergone a feasibility study done by Marquette University.
				They concluded that the project is indeed achievable. Therefore, it is our goal to implement this technology as dynamic and economical as possible. The Microsoft HoloLens is and advanced piece of technology.
				It is currently only available to developers to use to create software. The piece itself is a headset that is worn by the user that provides a screen that displays augmented reality.
				Augmented reality establishes a computer-generated image on the usere's view of the real world. The holoLens includes multiple sensors, advanced optics, and a custom holographic processing unit.
				The sensors allow the user to interact with their scene. The optics generate a clear and dynamic picture for the user. Lastly, the processing unit provides a strong refresh rate and render of the display,
				and it has the capacity to compute high level logic.</span>
				<br/><br/>
				<span>We believe that the HoloLens is the perfect piece of hardware to use to pursue the challenge that the physical therapists are battling.
				It has the ability to sense the patient, provide a field of vision, perform computations, and send that video to a database in the cloud.
				The HoloLens will provide the user with a scene that explains what kind of exercise they must establish. The HoloLens will then be able to advise the user if they are performing the exercise correctly.
				In addition to that, the physical therapist will be able to review the training videos of their patients to see their improvements.</span>
				<br/><br/>
				<span style={{fontWeight: "bold"}}>Subscribe below to get updates on this exciting project </span>
		</div>
	);

	// Input components
	const emailInput = () => (<label>
		Email: <span className='error'>{emailError}</span>
		<input 
			type="text" 
			name="email" 
			placeholder="Email" 
			value={email}
			onChange={(e)=>update_email(e.target.value)}
			required/>
	</label>);
	
	const firstNameInput = () => (<label>
		First Name: <span className='error'>{firstNameError}</span>
		<input 
			type="text" 
			name="firstName" 
			placeholder="First Name" 
			value={firstName}
			onChange={(e)=>update_firstName(e.target.value)}
			required/>
	</label>);
	
	const lastNameInput = () => (<label>
		Last Name: <span className='error'>{lastNameError}</span>
		<input 
			type="text" 
			name="lastName" 
			placeholder="Last Name"
			value={lastName}
			onChange={(e)=>update_lastName(e.target.value)}
			required/>
	</label>);

	//create new subscription object
	const subscribe = () => {
		
		let validEmail = validateEmail(email);
		let validFirstName = firstName.length > 0;
		let validLastName = lastName.length > 0;
		
		setEmailError(validEmail ? "": "- Invalid email!")
		setFirstNameError(validFirstName ? "": "- Please enter your first name.")
		setLastNameError(validLastName ? "": "- Please enter your last name.")

		if(validEmail && (validFirstName) && validLastName )
		{
			const newSub = {
				email,
				firstName,
				lastName
			}
			
			setNewSubscribed(newSub);

			update_email('');
			update_firstName('');
			update_lastName('');

			setConfirmMessage('Thank you for subscribing!');
			
			return;
		}
		setConfirmMessage('');
	}

  return (
        <section className="content">
            <div className="transbox">
                <AboutText/>
            </div>
            <div className="transbox">
                <div className= 'inputForm'
                    style={{
                    display: 'flex',
                    flexDirection: 'column'}}>

                        {"Email Subscription"}
						<br/>
                        {emailInput()}
                        {firstNameInput()}
                        {lastNameInput()}
                        <br/>

                        <div className='formButton'
                            onClick={() => subscribe()}>
                            {"Submit!"}
                        </div>
						<span style={{color: 'green'}}>{confirmMessage}</span>
                    </div>
                </div>
        </section>
	);
};

export default About;
