import React from "react";

const Meetteam = () => {
	return(
		<section className="content">
				<TeamMember teammate={teammate1}/>
				<TeamMember teammate={teammate2}/>
				<TeamMember teammate={teammate3}/>
		</section>
	);
}

function TeamMember ({ teammate }) {
	const { name, title, img, txt } = teammate;
	return(
		<div className="transbox">
			<div className="text-display">
				<h1>{name}</h1>
				<h1>{title}</h1>
				<img src={img} alt="Team Member"/>
				<span>{txt}</span>
			</div>
		</div>
	);
}

// Placeholder objects for each team member.
const teammate1 = {
	name: "John Doe",
	title: "Founder",
	img: "http://fpoimg.com/300x250?text=Preview",
	txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac neque sit amet purus rutrum bibendum quis vitae dolor. Integer mi ligula, fermentum nec aliquet non, hendrerit sed felis. Donec egestas condimentum ultricies. Etiam nec ante justo. Etiam molestie congue risus. Vestibulum faucibus nisi in lectus laoreet molestie. Nullam et bibendum libero. Ut sem tortor, eleifend vel commodo in, facilisis vitae odio. Cras vitae ante eleifend, mattis leo at, accumsan est. Nam vitae malesuada libero, a malesuada ipsum. Curabitur fringilla non mauris id facilisis. Suspendisse potenti."	
}

const teammate2 = {
	name: "Jane Doe",
	title: "Developer",
	img: "http://fpoimg.com/300x250?text=Preview",
	txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac neque sit amet purus rutrum bibendum quis vitae dolor. Integer mi ligula, fermentum nec aliquet non, hendrerit sed felis. Donec egestas condimentum ultricies. Etiam nec ante justo. Etiam molestie congue risus. Vestibulum faucibus nisi in lectus laoreet molestie. Nullam et bibendum libero. Ut sem tortor, eleifend vel commodo in, facilisis vitae odio. Cras vitae ante eleifend, mattis leo at, accumsan est. Nam vitae malesuada libero, a malesuada ipsum. Curabitur fringilla non mauris id facilisis. Suspendisse potenti."	
}

const teammate3 ={
	name: "John Smith",
	title: "Developer",
	img: "http://fpoimg.com/300x250?text=Preview",
	txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac neque sit amet purus rutrum bibendum quis vitae dolor. Integer mi ligula, fermentum nec aliquet non, hendrerit sed felis. Donec egestas condimentum ultricies. Etiam nec ante justo. Etiam molestie congue risus. Vestibulum faucibus nisi in lectus laoreet molestie. Nullam et bibendum libero. Ut sem tortor, eleifend vel commodo in, facilisis vitae odio. Cras vitae ante eleifend, mattis leo at, accumsan est. Nam vitae malesuada libero, a malesuada ipsum. Curabitur fringilla non mauris id facilisis. Suspendisse potenti."	
}

export default Meetteam;