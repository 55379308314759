import React from 'react';
import homeImg from './img/homeimage.jpg'
import therapist from './img/therapist.jpeg'
import patient1 from './img/patient-1.jpeg'
import patient2 from './img/patient-2.jpeg'
import './css/home.css'

const HomeImage = () => (
	<div className="home">
		<img src={homeImg} alt="Home" className="home-image"/>
		<span className="home-text">Therapy On Your Schedule</span>
	</div>
);

function Testimonial({ testimony }) {
	const { img, txt, title } = testimony;
	return(
		<div className="testimony">
			<img src={img} alt="Testimonial" className="testimony-image"/>
			<span className="testimony-title">{title}</span>
			<span className="testimony-text">{txt}</span>
		</div>
	);
}

const testimony1 = {
	img: Object.values({therapist}),
	txt: "As a therapist the new technology of Augmented Reality Physical Therapy has helped improve the care of my patients. " +
	"It has allowed us to better measure the accuracy of their at home sessions.",
	title: "Therapist Testimonial"
}

const testimony2 = {
	img: Object.values({patient1}),
	txt: "It has helped me get better faster and feel more confortable while doing exercises. " +
	"The precision of being able to know right away if my exercise was done correctly has made me more confident.",
	title: "Patient Testimonial"
}

const testimony3 ={
	img: Object.values({patient2}),
	txt: "The challenging game aspect of the HoloLens made my therapy a lot more enjoyable to do. " + 
	"More therapists need to have this option!",
	title: "Patient Testimonial"
}

function Home() {
	return (
		<div className="homeContent" >
			<HomeImage/>
			<div className="testimonials">
			<Testimonial testimony={testimony1}/>
			<Testimonial testimony={testimony2}/>
			<Testimonial testimony={testimony3}/>
			</div>
		</div>
	);
}

export default Home;
