import React, {useState} from "react";
import {stateSignupOptions, getCurrentDate} from "./data/utils.js";
import { validateEmail, validatePassword } from "./data/validation.js";

const Signup = ({ signUpNewUser, whitelist }) => {

    // Form Error/validation messages
    const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [cityError, setCityError] = useState('');
	const [confirmMessage, setConfirmMessage] = useState('');

    //input hooks
    const [firstName, update_firstName] = useState('');
    const [lastName, update_lastName] = useState('');
    const [email, update_email] = useState('');
    const [state, update_state] = useState('Alabama');
    const [city, update_city] = useState('');
    const [password, update_password] = useState('');
    const [confirmPass, update_confirmPass] = useState('');

    const SignupText = () => (
        <div className="text-display">
            <h1>Experience Physical Therapy in a whole new way.</h1>
        </div>
    );

    //input components
    const emailInput = () => (<label>
        Email: <span className='error'>{emailError}</span>
        <input 
            type="text" 
            name="email" 
            placeholder="Email" 
            value={email}
            onChange={(e)=>update_email(e.target.value)}
            required/>
	</label>)
	
    const firstNameInput = () => (<label>
        First Name: <span className='error'>{firstNameError}</span>
        <input 
            type="text" 
            name="firstName" 
            placeholder="First Name" 
            value={firstName}
            onChange={(e)=>update_firstName(e.target.value)}
            required/>
	</label>)
	
    const lastNameInput = () => (<label>
        Last Name: <span className='error'>{lastNameError}</span>
        <input 
            type="text" 
            name="lastName" 
            placeholder="Last Name"
            value={lastName}
            onChange={(e)=>update_lastName(e.target.value)}
            required/>
	</label>)
	
    const cityInput = () => (<label>
        City: <span className='error'>{cityError}</span>
        <input 
            type="text" 
            name="city" 
            placeholder="City" 
            value={city}
            onChange={(e)=>update_city(e.target.value)}
            required/>
	</label>)
	
    const stateInput = () => (<label>
        State:
        <select 
            name="state" 
            id="state"
            value={state}
            onChange={(e)=>update_state(e.target.value)}
            required>
            {stateSignupOptions()}
        </select>
	</label>)
	
    const passwordInput = () => (<label>
        Password: <span className='error'>{passwordError}</span>
        <input 
            type="password" 
            name="password" 
            placeholder="Password" 
            value={password}
            onChange={(e)=>update_password(e.target.value)}
            required/>
	</label>)
	
    const confirmPassInput = () => (<label>
        Confirm Password:
        <input 
            type="password" 
            name="confirmPass" 
            placeholder="Confirm Password" 
            value={confirmPass}
            onChange={(e)=>update_confirmPass(e.target.value)}
            required/>
    </label>)
    
    const submitUser = () => {
        
		let validEmail = validateEmail(email);
		let validPassword = validatePassword(password);
		let validFirstName = firstName.length > 0;
		let validLastName = lastName.length > 0;
		let validCity = city.length > 0;
        setEmailError(validEmail ? '': '- Invalid email!')
        setPasswordError(validPassword ? '': '- You must add a password!')
		setFirstNameError(validFirstName ? '': '- Enter your first name.')
		setLastNameError(validLastName ? '': '- Enter your last name.')
		setCityError(validCity ? '': '- Enter a city.')

        if (validEmail && validPassword && validFirstName && validLastName && validCity)
        { 
            if (password === confirmPass) 
            {
            
            //generated user props
            const signUpDate=getCurrentDate();
            var isTherapist=false;

            //is user therapist?
			for(let i = 0; i < whitelist.length; i++) 
			{
				if(email.includes(whitelist[i]))
				{
					isTherapist = true;
				}
			}
            
            const newUser = {
                firstName,
                lastName,
                email,
                state,
                city,
                password,
                signUpDate,
                isTherapist,
            }

            signUpNewUser(newUser);

            update_email('')
            update_firstName('')
            update_lastName('')
            update_city('')
            update_state('Alabama')
            update_password('')
            update_confirmPass('')

            setConfirmMessage('Sign-up successful');
            return;

            }//passwords don't match

            setPasswordError('Passwords do not match!')

            update_password('')
            update_confirmPass('')
            return;

         }
    }
    
    //render the page
    return (
        <section className="content">
            <div className="transbox">
                <SignupText/>
            </div>
            <div className="transbox">
                <div className= 'inputForm'
                    style={{
                    display: 'flex',
                    flexDirection: 'column'}}>
                        
                        {emailInput()}
                        {firstNameInput()}
                        {lastNameInput()}
                        {cityInput()}
                        {stateInput()}
                        {passwordInput()}
                        {confirmPassInput()}

                        <br/>

                        <div className='formButton'
                            onClick={() => submitUser()}>
                            {"Submit!"}
                        </div>
						<span style={{color: 'green'}}>{confirmMessage}</span>
                    </div>
                </div>
        </section>
    );
}

export default Signup;
