/*
	Hard-coded json objects stored as const which should ideally be moved to a database.
*/

// Initial hard-coded user array
export const initUsers = 
	[
		{
			firstName: "John",
			lastName: "Doe",
			email: "johnDoe@generic.com",
			state: "Wisconsin",
			city: "Stevens Point",
			password: "123password",
			id: "0001",
			signUpDate: "placeholder",
			isTherapist: false
		},
		{
			firstName: "Jane",
			lastName: "Doe",
			email: "janeDoe@generic.com",
			state: "Wisconsin",
			city: "Stevens Point",
			password: "321password",
			id: "0002",
			signUpDate: "placeholder",
			isTherapist: false
		},
		{
			firstName: "Tommy",
			lastName: "Placeholder",
			email: "tp@generic.com",
			state: "Wisconsin",
			city: "Stevens Point",
			password: "pass",
			id: "0005",
			signUpDate: "placeholder",
			isTherapist: false
		},
		{
			firstName: "Greg",
			lastName: "Generic",
			email: "greg@generic.com",
			state: "Wisconsin",
			city: "Stevens Point",
			password: "genericpass",
			id: "0003",
			signUpDate: "placeholder",
			isTherapist: true
		},
		{
			firstName: "Greta",
			lastName: "Generic",
			email: "email@generic.com",
			state: "Wisconsin",
			city: "Stevens Point",
			password: "123",
			id: "0004",
			signUpDate: "placeholder",
			isTherapist: true
		}
	];

// Relationships between hard coded users
export const initPatientToTherapist = 
	[
		{
			therapistId: "0003",
			patientId: "0002"
		},
		{
			therapistId: "0004",
			patientId: "0001"
		}
	]

// Emails associated with a therapist.
export const therapistEmailWhitelist = ["therapy.net", "fitness.com", "example.net"];