
const RegEx = 
	{
		email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
	};

export function validateEmail(inputString) {
    if(inputString.match(RegEx.email)) {
        return true;
    }
    return false;
}
    
export function validatePassword(inputString) {
    if(inputString.length > 0) {
        return true;
    }
    return false;
}