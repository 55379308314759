import React from "react";

export function stateSignupOptions() {
	return(
	<>
	<option value="Alabama">AL</option>
	<option value="Alaska">AK</option>
	<option value="Arizona">AZ</option>
	<option value="Arkansas">AR</option>
	<option value="California">CA</option>
	<option value="Colorado">CO</option>
	<option value="Connecticut">CT</option>
	<option value="Delaware">DE</option>
	<option value="Florida">FL</option>
	<option value="Georgia">GA</option>
	<option value="Hawaii">HI</option>
	<option value="Idaho">ID</option>
	<option value="Illinois">IL</option>
	<option value="Indiana">IN</option>
	<option value="Iowa">IA</option>
	<option value="Kansas">KS</option>
	<option value="Kentucky">KY</option>
	<option value="Louisiana">LA</option>
	<option value="Maine">ME</option>
	<option value="Maryland">MD</option>
	<option value="Massachusetts">MA</option>
	<option value="Michigan">MI</option>
	<option value="Minnesota">MN</option>
	<option value="Mississipi">MS</option>
	<option value="Missouri">MO</option>
	<option value="Montana">MT</option>
	<option value="Nebraska">NE</option>
	<option value="Nevada">NV</option>
	<option value="NewHampshire">NH</option>
	<option value="NewJersey">NJ</option>
	<option value="NewMexico">NM</option>
	<option value="NewYork">NY</option>
	<option value="NorthCarolina">NC</option>
	<option value="NorthDakota">ND</option>
	<option value="Ohio">OH</option>
	<option value="Oklahoma">OK</option>
	<option value="Oregon">OR</option>
	<option value="Pennsylvania">PA</option>
	<option value="RhodeIsland">RI</option>
	<option value="SouthCarolina">SC</option>
	<option value="SouthDakota">SD</option>
	<option value="Tennessee">TN</option>
	<option value="Texas">TX</option>
	<option value="Utah">UT</option>
	<option value="Vermont">VT</option>
	<option value="Virginia">VA</option>
	<option value="Washington">WA</option>
	<option value="WestVirginia">WV</option>
	<option value="Wisconsin">WI</option>
	<option value="Wyoming">WY</option>
	</>
	)
}

export function getCurrentDate(separator='/')
	{
		let newDate = new Date()
		let date = newDate.getDate();
		let month = newDate.getMonth() + 1;
		let year = newDate.getFullYear();
		
		return `${month<10?`0${month}`:`${month}`}${separator}${date}${separator}${year}`
	}