import React from "react";
import prototype1 from "./img/Arm-Raise-Ball.jpg";
import prototype2 from "./img/Exercise-Screen-with-Hand.jpg";
import prototype3 from "./img/Shoulder-Raise-Pic-Reps-Complete.jpg";

// Slideshow images 
const images = 
	[Object.values({prototype1}), 
	Object.values({prototype2}), 
	Object.values({prototype3})];
	
const Prototype = () => {
	return(
		<div className="content">
			<h1 style={{textAlign: "center"}}>Prototype</h1>
			<br/>
			<Slideshow images={images}/>
		</div>
	);
}

/* A slideshow with next/previous buttons and selectors. Intended to scale in size: simply add or remove an image from the images array.
   Implemented as a component class. Might be cleaner if implemented with state hooks.*/ 
class Slideshow extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			slideIndex: 0
		};
		this.showSlide = this.showSlide.bind(this);
	}
	
	showSlide(index){
		// Retrieve the slides and selectors
		var slides = document.getElementsByClassName("slide");
		var imgSelectors = document.getElementsByClassName("image-selector");
		
		// Deactivate the current slide
		slides[this.state.slideIndex].style.display = "none";
		imgSelectors[this.state.slideIndex].className =
			imgSelectors[this.state.slideIndex].className.replace(" active", "");
			
		// Activate new slide and set the state 
		if (index > slides.length - 1) {index = 0};
		if (index < 0) {index = slides.length - 1};
		slides[index].style.display = "block";
		imgSelectors[index].className += " active";
		this.setState({
			slideIndex: index
		});
	}
	
	componentDidMount(){
		this.showSlide(0);
	}
	
	render(){
		const slides = this.props.images.map((images) =>
				<div className="slide">
					<img src={images} alt="prototype"/>
				</div>
		);
		const imgSelectors = [];
		for(let i = 0; i < this.props.images.length; i++) {
			imgSelectors.push(
				<span className="image-selector" onClick={() => this.showSlide(i)}/>
			);
		}
		return(
			<div>
				<div className="slideshow-container">
					{slides}
					<button className="prev-slide" onClick={() => this.showSlide(this.state.slideIndex - 1)}>&#10094;</button>
					<button className="next-slide" onClick={() => this.showSlide(this.state.slideIndex + 1)}>&#10095;</button>
				</div>
				<div style={{textAlign: "center"}}>
					{imgSelectors}
				</div>
			</div>
		);
	}
}

export default Prototype;